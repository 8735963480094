import React from 'react';
import { Link } from 'react-router-dom'
import 'react-bulma-components/dist/react-bulma-components.min.css';  
import { Level, Heading } from 'react-bulma-components';

class NavBar extends React.Component {
  render(){
    return (
      <Level renderAs="nav">
        <Level.Side align="left">
          <Level.Item>
            <Heading size={5} subtitle>
              <Link to="/">
                Anonymous HatenaBookMark
              </Link>
            </Heading>
          </Level.Item>
        </Level.Side>
      </Level>
    )
  }
}

export default NavBar;