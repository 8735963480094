import React from 'react';

import * as grpcWeb from 'grpc-web';
// import {GreeterClient} from './proto/greeter_grpc_web_pb';
// import {HelloRequest, HelloReply} from './proto/greeter_pb';
import {HatebClient} from './proto/hateb_grpc_web_pb';
import {FeedRequest, FeedReply, BookMarkReply} from './proto/hateb_pb';

import BookMark from './BookMark'

// const greetService = new GreeterClient('https://api.anondhateb.com:443', null, null);
const hatebService = new HatebClient('https://api.hbm.programmeboy.com:443', null, null); // capitalize

interface BookMarkReplyListState {
  bookmarks: BookMarkReply[];
}

class Feed extends React.Component<any, BookMarkReplyListState> {
  constructor(props: any) {
    super(props);

    this.state = {bookmarks: []};

    // const request: HelloRequest = new HelloRequest();
    // request.setName('foo bar!!!!');
    const request: FeedRequest = new FeedRequest();

    //const call = greetService.sayHello(request, {'custom-header-1': 'value1'},
    hatebService.getFeed(request, {'custom-header-1': 'value1'}, 
    (err: grpcWeb.Error, response: FeedReply) => {
      // if (err) console.log(err);
      console.log(response.getItemList());

      this.setState({
        bookmarks: response.getItemList()
      })
    });
  }

  render(){
    return (
      <div className="column">
        <div className="box content">
        {this.state.bookmarks.map((v: BookMarkReply, index: number) => {
          return <BookMark bookMarkReply={v} key={v.getDate().getSeconds()} />
        })}
        </div>
      </div>
    );
  }
  
}

export default Feed;