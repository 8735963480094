import * as grpcWeb from 'grpc-web';
import {HatebClient} from '../proto/hateb_grpc_web_pb';
import {BookMarkCommentRequest, BookMarkCommentReply} from '../proto/hateb_pb';

const hatebService = new HatebClient('https://api.hbm.programmeboy.com:443', null, null); // capitalize

export const getBookMarkComment = (url: string, callback: (err: grpcWeb.Error,
  response: BookMarkCommentReply) => void): void => {

  const request: BookMarkCommentRequest = new BookMarkCommentRequest();
  request.setUrl(url);

  hatebService.getBookMarkComment(request, {'custom-header-1': 'value1'}, callback);
}
