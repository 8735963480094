/* eslint-disable */
/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.proto = require('./hateb_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.HatebClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.HatebPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.FeedRequest,
 *   !proto.proto.FeedReply>}
 */
const methodDescriptor_Hateb_GetFeed = new grpc.web.MethodDescriptor(
  '/proto.Hateb/GetFeed',
  grpc.web.MethodType.UNARY,
  proto.proto.FeedRequest,
  proto.proto.FeedReply,
  /**
   * @param {!proto.proto.FeedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FeedReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.FeedRequest,
 *   !proto.proto.FeedReply>}
 */
const methodInfo_Hateb_GetFeed = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.FeedReply,
  /**
   * @param {!proto.proto.FeedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FeedReply.deserializeBinary
);


/**
 * @param {!proto.proto.FeedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.FeedReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.FeedReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HatebClient.prototype.getFeed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Hateb/GetFeed',
      request,
      metadata || {},
      methodDescriptor_Hateb_GetFeed,
      callback);
};


/**
 * @param {!proto.proto.FeedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.FeedReply>}
 *     A native promise that resolves to the response
 */
proto.proto.HatebPromiseClient.prototype.getFeed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Hateb/GetFeed',
      request,
      metadata || {},
      methodDescriptor_Hateb_GetFeed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BookMarkCommentRequest,
 *   !proto.proto.BookMarkCommentReply>}
 */
const methodDescriptor_Hateb_GetBookMarkComment = new grpc.web.MethodDescriptor(
  '/proto.Hateb/GetBookMarkComment',
  grpc.web.MethodType.UNARY,
  proto.proto.BookMarkCommentRequest,
  proto.proto.BookMarkCommentReply,
  /**
   * @param {!proto.proto.BookMarkCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookMarkCommentReply.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.proto.BookMarkCommentRequest,
 *   !proto.proto.BookMarkCommentReply>}
 */
const methodInfo_Hateb_GetBookMarkComment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.proto.BookMarkCommentReply,
  /**
   * @param {!proto.proto.BookMarkCommentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookMarkCommentReply.deserializeBinary
);


/**
 * @param {!proto.proto.BookMarkCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.proto.BookMarkCommentReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookMarkCommentReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.HatebClient.prototype.getBookMarkComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Hateb/GetBookMarkComment',
      request,
      metadata || {},
      methodDescriptor_Hateb_GetBookMarkComment,
      callback);
};


/**
 * @param {!proto.proto.BookMarkCommentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookMarkCommentReply>}
 *     A native promise that resolves to the response
 */
proto.proto.HatebPromiseClient.prototype.getBookMarkComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Hateb/GetBookMarkComment',
      request,
      metadata || {},
      methodDescriptor_Hateb_GetBookMarkComment);
};


module.exports = proto.proto;

