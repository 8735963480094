import React from 'react';
import { Link } from 'react-router-dom'

import 'react-bulma-components/dist/react-bulma-components.min.css';  
import './BookMarkReply.css';

import {BookMarkReply} from './proto/hateb_pb';

interface BookMarkReplyState {
  bookMarkReply :BookMarkReply
}
  
class BookMark extends React.Component<BookMarkReplyState> {
  render(){
    let url = "/comment/" + encodeURIComponent(this.props.bookMarkReply.getLink());

    return (
      <article className="post">
        <div className="media">
          <div>
            <a href={this.props.bookMarkReply.getLink()} target="_blank" rel="noopener noreferrer">{this.props.bookMarkReply.getTitle()}</a>
          </div>
          <div className="media-content">
          </div>
          <div className="media-right">
            <Link to={url}>Comment</Link>
          </div>
        </div>
      </article>
    )
  }
}

export default BookMark;