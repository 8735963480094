import React from 'react';
import { withRouter } from "react-router-dom";

import * as grpcWeb from 'grpc-web';
import {BookMarkCommentReply, CommentReply} from './proto/hateb_pb';
import {getBookMarkComment} from './action/hatebService'

import Comment from './Comment'

interface CommentReplyListState {
  comments: CommentReply[];
}

class BookMarkComment extends React.Component<any, CommentReplyListState> {
  constructor(props: any) {
    super(props);

    this.state = {comments: []};
    let url = decodeURIComponent(this.props.match.params.url);

    const t = this;
    let callback = function(err: grpcWeb.Error, response: BookMarkCommentReply): void {
      // if (err) console.log(err);
      console.log(response.getCommentList());

      t.setState({
        comments: response.getCommentList()
      })
    }

    getBookMarkComment(url, callback);

  }

  render(){
    return (
      <div className="column">
        <div className="box content">
        {this.state.comments.map((v: CommentReply, index: number) => {
          return <Comment commentReply={v} key={v.getUser()} />
        })}
        </div>
      </div>
    );
  }
  
}

export default withRouter(BookMarkComment);