import React from 'react';

import 'react-bulma-components/dist/react-bulma-components.min.css';  
import './CommentReply.css';

import {CommentReply} from './proto/hateb_pb';

interface CommentReplyState {
  commentReply :CommentReply
}
  
class Comment extends React.Component<CommentReplyState> {
  render(){

    return (
      <article className="post">
        <div className="media">
          <div>
            {this.props.commentReply.getCommnet()}
          </div>
          <div className="media-content">
          </div>
          <div className="media-right">
          </div>
        </div>
      </article>
    )
  }
}

export default Comment;